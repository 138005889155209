<template>
  <div>
    <StatusSummary
      :statuses="statuses"
    ></StatusSummary>

    <b-tabs
      active-nav-item-class="font-weight-bold"
    >
      <b-tab
        v-if="receivedHasData"
        title="Diterima"
        @click="receivedTabSelect()"
        lazy
      >
        <DataList
          :title="'Senarai permohonan untuk diproses'"
          :appsData="pagedAppsRoleProcRcvd"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="true"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>


      <b-tab
        v-if="toApproveHasData"
        title="Menunggu Kelulusan"
        @click="toApprovedTabSelect()"
        lazy
      >
        <DataList
          :title="'Senarai permohonan yang telah diproses dan dihantar untuk kelulusan'"
          :appsData="pagedAppsRoleProcToAppr"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="true"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>


      <b-tab
        v-if="remarkedHasData"
        title="Dengan Remarks"
          @click="remarkedTabSelect()"
          lazy
      >
        <DataList
          :title="'Senarai permohonan untuk disemak semula'"
          :appsData="pagedAppsRoleProcRemark"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="true"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>

      <b-tab
        v-if="approvedHasData"
        title="Diluluskan"
        @click="approvedTabSelect()"
        lazy
      >
        <DataList
          :title="'Senarai permohonan yang telah lulus'"
          :appsData="pagedAppsRoleProcApprvd"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="true"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>

      <!-- v-if="rejectHasData" -->
      <b-tab
        title="Ditolak"
        lazy
        @click="rejectedTabSelect()"
      >
        <DataList
          :title="'Senarai permohonan yang ditolak'"
          :appsData="pagedAppsRoleProcReject"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="true"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>

    </b-tabs>

    <ErrorMsgDialog
      v-model="msgERR.show"
      :modal="msgERR.modal"
      :title="msgERR.title"
      :content="msgERR.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      :isTypeError="true"
      proceedButton="OK"
    ></ErrorMsgDialog>

    <InfoMsgDialog
      v-model="msgINFO.show"
      :modal="msgINFO.modal"
      :title="msgINFO.title"
      :content="msgINFO.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      proceedButton="OK"
      @onProceed="infoProceedOK()"
    ></InfoMsgDialog>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  errorMessages,
  extractPagingInfoOnly,
} from "@/core/services/helper.service";

import {
  GET_APPS_ROLE_PROC_RCVD,
  GET_APPS_ROLE_PROC_TOAPPR,
  GET_APPS_ROLE_PROC_APPRVD,
  GET_APPS_ROLE_PROC_REMARK,
  GET_APPS_ROLE_PROC_REJECT,
} from "@/core/services/store/application.module";

import DataList from "@/view/pages/widgets/DataList.vue";
import StatusSummary from "@/view/pages/widgets/StatusSummary.vue";

export default {
  name: "ProcessingDataList",

  components: {
    DataList,
    StatusSummary,
  },

  props: {
    //
  },

  data() {
    return {
      overlay: true,

      msgERR: {
        show: false,
        modal: false,
        title: "Ralat",
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        show: false,
        modal: false,
        title: "Info",
        message: "",
        nric: "",
        run: 0,
      },

      statuses: [
        {
          id: 1,
          title: "Permohonan",
          value: "20",
          class: "blue",
        },
        {
          id: 2,
          title: "Lulus",
          value: 2,
          class: "green",
        }
      ],

      headers: [
        {
          text: "No. Rujukan",
          value: "ref_no",
          type: String,
        },
        {
          text: "Tarikh Mohon",
          value: "submitted_date",
          type: Date,
          filter: "formatDateTime",
        },
        {
          text: "Status",
          value: "status.nameMS",
          color: "status.color_class",
          type: String,
          filter: "upperCase",
          class_template: {
            "h-25": true,
            "label": true,
            "label-md": true,
            "label-inline": true,
            "font-weight-bold": true,
          },
        },
        {
          text: "Tarikh Kelulusan",
          value: "approval_date",
          type: Date,
          filter: "formatDate",
        },
        {
          text: "Tarikh Pembayaran",
          value: "disb_date",
          type: Date,
          filter: "formatDate",
        },
        {
          text: "Jumlah Pembiayaan Diluluskan",
          value: "approved_amount",
          type: Number,
          filter: "fAmount",
          style: "white-space: nowrap;",
          class_template: {
            "text-primary": true,
          },
        },
        {
          text: "Surat Tawaran",
          value: "media_name",
          type: String,
        },
        {
          text: "Nota",
          value: "remark",
          type: String,
          tooltip: {
            style: "white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 270px;",
            class: "text-dark-75",
          }
        },
      ],

    };
  },

  computed: {
    ...mapGetters([
      "pagedAppsRoleProcRcvd",
      "pagedAppsRoleProcToAppr",
      "pagedAppsRoleProcRemark",
      "pagedAppsRoleProcApprvd",
      "pagedAppsRoleProcReject",
    ]),

    receivedHasData() {
      return !!(this.pagedAppsRoleProcRcvd?.data?.length ?? 0 > 0);
    },

    toApproveHasData() {
      return !!(this.pagedAppsRoleProcToAppr?.data?.length ?? 0 > 0);
    },

    remarkedHasData() {
      return !!(this.pagedAppsRoleProcRemark?.data?.length ?? 0 > 0);
    },

    approvedHasData() {
      return !!(this.pagedAppsRoleProcApprvd?.data?.length ?? 0 > 0);
    },

    rejectHasData() {
      return !!(this.pagedAppsRoleProcReject?.data?.length ?? 0 > 0);
    },

  },

  methods: {
    receivedTabSelect() {
      //nfh-console.log('receivedTabSelect');
      this.$store.dispatch(GET_APPS_ROLE_PROC_RCVD);
    },

    toApprovedTabSelect() {
      //nfh-console.log('toApprovedTabSelect');
      this.$store.dispatch(GET_APPS_ROLE_PROC_TOAPPR);
    },

    remarkedTabSelect() {
      //nfh-console.log('remarkedTabSelect');
      this.$store.dispatch(GET_APPS_ROLE_PROC_REMARK);
    },

    approvedTabSelect() {
      //nfh-console.log('approvedTabSelect');
      this.$store.dispatch(GET_APPS_ROLE_PROC_APPRVD);
    },

    rejectedTabSelect() {
      //nfh-console.log('rejectedTabSelect');
      this.$store.dispatch(GET_APPS_ROLE_PROC_REJECT);
    },

    // async loadPageDataProc(page) {
    //   console.log('loadPageDataProc - page: ', page);
    // },

    // async loadPageDataToApprove(page) {
    //   console.log('loadPageDataToApprove - page: ', page);
    // },

    // async loadPageDataRemark(page) {
    //   console.log('loadPageDataRemark - page: ', page);
    // },

    // async loadPageDataApproved(page) {
    //   console.log('loadPageDataApproved - page: ', page);
    // },

    goToEditPage(data) {
      this.$router.push({
        name: "EditForm",
        params: { id: data.id },
      });
    },

    goToDeleteApp(data) {
    },

    // rejectApplication(form) {
    //   this.$emit("showAlertRejectApplicationDialog", form);
    // },

    promptError(message, title = null) {
      this.msgERR.message = errorMessages(message);
      this.msgERR.title = (title) ? title : "";
      this.msgERR.show = true;
    },

    promptInfo(message, title = null, modal = false) {
      this.msgINFO.message = message;
      this.msgINFO.title = (title) ? title : "";
      this.msgINFO.modal = modal;
      this.msgINFO.show = true;
    },

    // openLogs(application) {
    //   this.$emit("showAppLogsDialog", application);
    // },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 200);
    },

  },

  mounted() {
    //nfh-console.log(" IN: MOUNTED");
    this.closeOverlay();

    this.$store.dispatch(GET_APPS_ROLE_PROC_RCVD);
    this.$store.dispatch(GET_APPS_ROLE_PROC_TOAPPR);
    this.$store.dispatch(GET_APPS_ROLE_PROC_REMARK);
    this.$store.dispatch(GET_APPS_ROLE_PROC_REJECT);
    this.$store.dispatch(GET_APPS_ROLE_PROC_APPRVD);

    //nfh-console.log("OUT: MOUNTED");
  },

};
</script>