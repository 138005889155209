var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("StatusSummary", { attrs: { statuses: _vm.statuses } }),
      _c(
        "b-tabs",
        { attrs: { "active-nav-item-class": "font-weight-bold" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Perlu Bantuan", lazy: "" } },
            [
              _c("DataList", {
                attrs: {
                  title: "Permohonan Perlu Bantuan",
                  appsData: _vm.pagedAppsRoleSalesAssistable,
                  appsConfig: _vm.headers,
                  detailButton: true,
                  deleteButton: false,
                },
                on: {
                  onDetail: function ($event) {
                    return _vm.goToEditPage($event)
                  },
                  onDelete: function ($event) {
                    return _vm.goToDeleteApp($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Diproses", lazy: "" } },
            [
              _c("DataList", {
                attrs: {
                  title: "Senarai Permohonan Sedang Diproses",
                  appsData: _vm.pagedAppsRoleSalesProcess,
                  appsConfig: _vm.headers,
                  detailButton: true,
                  deleteButton: false,
                },
                on: {
                  onDetail: function ($event) {
                    return _vm.goToEditPage($event)
                  },
                  onDelete: function ($event) {
                    return _vm.goToDeleteApp($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Dengan Remarks", lazy: "" } },
            [
              _c("DataList", {
                attrs: {
                  title: "Senarai Permohonan Dengan Remarks",
                  appsData: _vm.pagedAppsRoleSalesRemarked,
                  appsConfig: _vm.headers,
                  detailButton: true,
                  deleteButton: false,
                },
                on: {
                  onDetail: function ($event) {
                    return _vm.goToEditPage($event)
                  },
                  onDelete: function ($event) {
                    return _vm.goToDeleteApp($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Diluluskan", lazy: "" } },
            [
              _c("DataList", {
                attrs: {
                  title: "Senarai Permohonan Telah Lulus",
                  appsData: _vm.pagedAppsRoleSalesApproved,
                  appsConfig: _vm.headers,
                  detailButton: true,
                  deleteButton: false,
                },
                on: {
                  onDetail: function ($event) {
                    return _vm.goToEditPage($event)
                  },
                  onDelete: function ($event) {
                    return _vm.goToDeleteApp($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          modal: _vm.msgERR.modal,
          title: _vm.msgERR.title,
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.msgERR.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgERR, "show", $$v)
          },
          expression: "msgERR.show",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          modal: _vm.msgINFO.modal,
          title: _vm.msgINFO.title,
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        on: {
          onProceed: function ($event) {
            return _vm.infoProceedOK()
          },
        },
        model: {
          value: _vm.msgINFO.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgINFO, "show", $$v)
          },
          expression: "msgINFO.show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }