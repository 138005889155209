<template>
  <v-row justify="center">
    <v-dialog v-model="displayRejectApplicationDialog" max-width="400">
      <v-card class="pa-6">
        <v-row>
          <v-col cols="12">
            <v-card-title class="px-0 py-0">
              <p class="font-weight-bold">{{ title }}</p>
            </v-card-title>
            <v-card-text class="px-0 py-0">
              <p class="body-1">{{ content }}</p>
              <ValidationObserver ref="remarkValidation">
                <v-form>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nota"
                    rules="required"
                  >
                    <v-textarea
                      label="Nota"
                      v-model="remark"
                      :error-messages="errors"
                      outlined
                      required
                    ></v-textarea>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>
          </v-col>

          <v-col
            class="d-flex flex-wrap justify-content-end align-items-center"
            cols="12"
          >
            <v-btn text v-if="showBackButton !== false" @click="closeDialog()">
              <span class="font-weight-bold">{{ backButton }}</span>
            </v-btn>
            <v-btn
              class="ml-4"
              color="primary"
              :loading="isLoading"
              v-if="showProceedButton !== false"
              @click="onProceed()"
            >
              <span class="font-weight-bold">{{ proceedButton }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

export default {
  props: {
    application: {},
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    proceedButton: {
      type: String,
      default: "",
    },
    backButton: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    showProceedButton: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: false,
      remark: "",
    };
  },
  computed: {
    displayRejectApplicationDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    closeDialog() {
      this.displayRejectApplicationDialog = false;
    },
    onProceed() {
      this.$refs.remarkValidation.validate().then((success) => {
        // xxconsole.log(success);
        if (success) {
          this.isLoading = true;
          setTimeout(() => {
            this.$emit("onProceed", this.remark);
            this.isLoading = false;
          }, 2000);
        }
      });
    },
  },
};
</script>
<style scoped></style>
