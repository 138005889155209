var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        { attrs: { "active-nav-item-class": "font-weight-bold" } },
        [
          _c("b-tab", { attrs: { title: "Tiada petugas", lazy: "" } }, [
            _c("div", { staticClass: "card card-custom gutter-b" }, [
              _c("div", { staticClass: "card-header border-0 py-5" }, [
                _c(
                  "h3",
                  { staticClass: "card-title align-items-start flex-column" },
                  [
                    _c(
                      "span",
                      { staticClass: "card-label font-weight-bolder" },
                      [_vm._v("Senarai Permohonan ")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card-body py-0" }, [
                _c("div", { staticClass: "table-responsive table-hover" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-head-custom table-vertical-center",
                    },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "text-center" }, [
                          _c("th", { staticClass: "pl-0" }, [_vm._v("No.")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Cawangan"),
                          ]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("No. Rujukan"),
                          ]),
                          _c("th", [_vm._v(" Tarikh Permohonan ")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Jenis Permohonan"),
                          ]),
                          _c("th", [_vm._v("Status")]),
                          _c("th", [_vm._v("Nota")]),
                          _c("th", [_vm._v("Ditugaskan")]),
                          _c(
                            "th",
                            {
                              staticClass: "pr-0 text-right",
                              staticStyle: { "min-width": "100px" },
                            },
                            [_vm._v(" Tindakan ")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            _vm.unassignedApplications.data,
                            function (unassignedApplication, i) {
                              return _c(
                                "tr",
                                {
                                  key: unassignedApplication.id,
                                  staticClass: "text-center",
                                },
                                [
                                  _c("td", { staticClass: "pl-0" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [_vm._v(_vm._s(i + 1))]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          label label-md label-inline\n                          font-weight-bolder\n                          h-25\n                          label-light-primary\n                        ",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              unassignedApplication.branch.name.toUpperCase()
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "pl-0" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(unassignedApplication.ref_no)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateTime")(
                                              unassignedApplication.submitted_date
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "pl-0" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            unassignedApplication
                                              .application_type.name
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-muted font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(unassignedApplication.subtitle)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          label label-md label-inline\n                          font-weight-bold\n                          h-25\n                        ",
                                        class:
                                          "label-light-" +
                                          unassignedApplication.status
                                            .color_class,
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            unassignedApplication.status.name
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "font-weight-bolder d-block font-size-md",
                                                            staticStyle: {
                                                              "white-space":
                                                                "nowrap",
                                                              "text-overflow":
                                                                "ellipsis",
                                                              overflow:
                                                                "hidden",
                                                              "max-width":
                                                                "400px",
                                                            },
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              unassignedApplication.remark
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                unassignedApplication.remark
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              unassignedApplication
                                                .sales_executive.user
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "pr-0 text-right" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "\n                          btn btn-icon btn-light btn-hover-primary btn-sm\n                          mx-3\n                        ",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToEditPage(
                                                unassignedApplication
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "svg-icon svg-icon-md svg-icon-primary",
                                            },
                                            [
                                              _c("inline-svg", {
                                                attrs: {
                                                  src: "media/svg/icons/General/Visible.svg",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "\n                          btn btn-icon btn-light btn-hover-primary btn-sm\n                        ",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openAssignApplicationDialog(
                                                unassignedApplication
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "svg-icon svg-icon-md svg-icon-primary",
                                            },
                                            [
                                              _c("inline-svg", {
                                                attrs: {
                                                  src: "media/svg/icons/Communication/Write.svg",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c("nav", [
                  _c(
                    "ul",
                    { staticClass: "pagination justify-content-end" },
                    _vm._l(_vm.applications.links, function (link) {
                      return _c("div", { key: link.label }, [
                        _c(
                          "li",
                          {
                            class:
                              "page-item " +
                              (link.url == null ? "disabled" : "") +
                              " " +
                              (link.active == true ? "active" : ""),
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "page-link",
                                attrs: { "aria-label": link.label },
                                on: {
                                  click: function ($event) {
                                    return _vm.paginate(link.url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.decodeHtml(link.label)))]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("b-tab", { attrs: { title: "Sudah Ditugaskan" } }, [
            _c("div", { staticClass: "card card-custom gutter-b" }, [
              _c("div", { staticClass: "card-header border-0 py-5" }, [
                _c(
                  "h3",
                  { staticClass: "card-title align-items-start flex-column" },
                  [
                    _c(
                      "span",
                      { staticClass: "card-label font-weight-bolder" },
                      [_vm._v("Senarai Permohonan ")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card-body py-0" }, [
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-head-custom table-vertical-center",
                    },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "text-center" }, [
                          _c("th", { staticClass: "pl-0" }, [_vm._v("No.")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Cawangan"),
                          ]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("No. Rujukan"),
                          ]),
                          _c("th", [_vm._v(" Tarikh Permohonan ")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Jenis Permohonan"),
                          ]),
                          _c("th", [_vm._v("Status")]),
                          _c("th", [_vm._v("Nota")]),
                          _c("th", [_vm._v("Ditugaskan")]),
                          _c(
                            "th",
                            {
                              staticClass: "pr-0 text-right",
                              staticStyle: { "min-width": "100px" },
                            },
                            [_vm._v(" Tindakan ")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            _vm.applications.data,
                            function (application, i) {
                              return _c(
                                "tr",
                                {
                                  key: application.id,
                                  staticClass: "text-center",
                                },
                                [
                                  _c("td", { staticClass: "pl-0" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(i + _vm.applications.from)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          label label-md label-inline\n                          font-weight-bolder\n                          h-25\n                          label-light-primary\n                        ",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              application.branch.name.toUpperCase()
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "pl-0" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [_vm._v(_vm._s(application.ref_no))]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateTime")(
                                              application.submitted_date
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "pl-0" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            application.application_type.name
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-muted font-weight-bold",
                                      },
                                      [_vm._v(_vm._s(application.subtitle))]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          label label-md label-inline\n                          font-weight-bold\n                          h-25\n                        ",
                                        class:
                                          "label-light-" +
                                          application.status.color_class,
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("upperCase")(
                                              application.status.nameMS
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "font-weight-bolder d-block font-size-md",
                                                            staticStyle: {
                                                              "white-space":
                                                                "nowrap",
                                                              "text-overflow":
                                                                "ellipsis",
                                                              overflow:
                                                                "hidden",
                                                              "max-width":
                                                                "400px",
                                                            },
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              application.remark
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(application.remark)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bolder d-block font-size-md",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              application.sales_executive
                                                ? application.sales_executive
                                                    .user.full_name
                                                : ""
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "pr-0 text-right" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "\n                          btn btn-icon btn-light btn-hover-primary btn-sm\n                          mx-3\n                        ",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToEditPage(
                                                application
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "svg-icon svg-icon-md svg-icon-primary",
                                            },
                                            [
                                              _c("inline-svg", {
                                                attrs: {
                                                  src: "media/svg/icons/General/Visible.svg",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "\n                          btn btn-icon btn-light btn-hover-primary btn-sm\n                        ",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openDeleteAssignedApplicationConfirmationDialog(
                                                application
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "svg-icon svg-icon-md svg-icon-primary",
                                            },
                                            [
                                              _c("inline-svg", {
                                                attrs: {
                                                  src: "media/svg/icons/General/Trash.svg",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c("nav", [
                  _c(
                    "ul",
                    { staticClass: "pagination justify-content-end" },
                    _vm._l(_vm.applications.links, function (link) {
                      return _c("div", { key: link.label }, [
                        _c(
                          "li",
                          {
                            class:
                              "page-item " +
                              (link.url == null ? "disabled" : "") +
                              " " +
                              (link.active == true ? "active" : ""),
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "page-link",
                                attrs: { "aria-label": link.label },
                                on: {
                                  click: function ($event) {
                                    return _vm.paginate(link.url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.decodeHtml(link.label)))]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }