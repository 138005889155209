<template>
  <div>
    <!--
      DASHBOARD DISPLAY PRIORITY BASED ON USER ROLE:
      1. APPROVER
      2. HQ PROCESSING
      3. SALES ONLINE
      4. MEMBER
     -->
    <!--begin::Dashboard-->
    <v-row justify="center">
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="1">
            <v-icon>{{ snackbar.icon }}</v-icon>
          </v-col>
          <v-col cols="10">
            <div class="mx-3">
              <p class="snackbar-title font-weight-bold mb-0">
                {{ snackbar.title }}
              </p>
              <p class="snackbar-subtitle mb-0">
                {{ snackbar.text }}
              </p>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn icon @click="snackbar.visible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-snackbar>

      <v-col cols="12" sm="12" md="12" lg="12" xl="12">

        <AlertActionable v-model="isShowAlert1"
          :alertMessage="alert1.message"
          :alertType="alert1.type"
          :button1Caption="alert1.button1Caption"
          :dismissable="alert1.dismissable"
          :outlined="false"
          @onAction1="goToChangePassword()"
        ></AlertActionable>

        <!-- BEGIN PRIORITY 1: APPROVER DASHBOARD -->
        <div v-if="currentUserRoles.includes(7)">
          <!-- <span class="font-weight-bold"> Approver </span> -->

          <BatchList
            :pendingApplicationBatches="pendingApplicationBatches"
            @showAlertDeleteApplicationDialog="showAlertDeleteApplicationDialog"
          ></BatchList>
        </div>
        <!-- END PRIORITY 1: APPROVER DASHBOARD -->

        <!-- BEGIN PRIORITY ?: BRANCH MANAGER (NOW KOWAMAS MEMBER) - NOT IMPLEMENTED -->
        <div v-else-if="currentUserRoles.includes(4)">
          <!-- <v-col cols="12">
            <span class="font-weight-bold"> Branch Manager </span>
          </v-col> -->
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <div class="card card-custom gutter-b red lighten-3">
                <div class="card-header border-0 py-5">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder"
                      >Jumlah Permohonan</span
                    >
                  </h3>
                </div>
                <div class="card-body py-0">
                  <h1
                    class="card-title align-items-start flex-column text-right"
                  >
                    <span class="card-label font-weight-bolder">{{
                      unassignedApplications.length + applications.length
                    }}</span>
                  </h1>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <div class="card card-custom gutter-b green lighten-3">
                <div class="card-header border-0 py-5">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder"
                      >Permohonan Lengkap</span
                    >
                  </h3>
                </div>
                <div class="card-body py-0">
                  <h1
                    class="card-title align-items-start flex-column text-right"
                  >
                    <span class="card-label font-weight-bolder">10</span>
                  </h1>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <div class="card card-custom gutter-b yellow lighten-3">
                <div class="card-header border-0 py-5">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder"
                      >Permohonan Tidak Lengkap</span
                    >
                  </h3>
                </div>
                <div class="card-body py-0">
                  <h1
                    class="card-title align-items-start flex-column text-right"
                  >
                    <span class="card-label font-weight-bolder">2</span>
                  </h1>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <BranchManagerDataList
                :applications="paginateApplications"
                :unassignedApplications="paginateUnassignedApplications"
                @paginate="paginate"
                @showAlertAssignApplicationDialog="
                  showAlertAssignApplicationDialog
                "
                @showDeleteAssignedApplicationConfirmationDialog="
                  showDeleteAssignedApplicationConfirmationDialog
                "
              >
              </BranchManagerDataList>
            </v-col>
          </v-row>
        </div>
        <!-- END PRIORITY ?: BRANCH MANAGER (NOW KOWAMAS MEMBER) - NOT IMPLEMENTED -->

        <!-- BEGIN PRIORITY 2: HQ PROCESSING -->
        <div
          v-else-if="currentUserRoles.includes(2)"
        >
          <v-row
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
            <!--
              :applications="paginateApplications"
              :remarksApplications="paginateRemarkApplications"
              :approvedApplications="approvedApplications"
              @showAlertRejectApplicationDialog="showAlertRejectApplicationDialog"
              @paginate="paginate"
              @showAppLogsDialog="showAppLogsDialog"
            -->
              <ProcessingDataList
              ></ProcessingDataList>
            </v-col>
          </v-row>
        </div>
        <!-- END PRIORITY 2: HQ PROCESSING -->

        <!-- BEGIN PRIORITY 3: SALES ONLINE -->
        <div
          v-else-if="currentUserRoles.includes(3)"
        >
          <v-row
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <!-- :needHelpApps="pagedAppsRoleSalesAssistable"
              :receivedApps="paginateApplications"
              :completeApps="completeApplications"
              :approvedApps="approvedApplications"
              @showAlertDeleteApplicationDialog="showAlertDeleteApplicationDialog"
              @paginate="paginate" -->
              <SalesOnlineDataList
              ></SalesOnlineDataList>
            </v-col>
          </v-row>
        </div>
        <!-- END PRIORITY 3: SALES ONLINE -->

        <!-- BEGIN PRIORITY 4: MEMBER -->
        <div v-else-if="currentUserRoles.includes(5)">
          <!-- <span class="font-weight-bold"> Member </span> -->
          <!-- :applications="paginateApplications" -->
          <!-- :applications="pagedAppsRoleMember"
          @paginate="paginate" -->
          <MemberDataList />
          <!-- <DataList
            :title="'Senarai Permohonan'"
            :appsData="pagedAppsRoleMember"
            :appsConfig="memberHeaders"
            :detailButton="true"
          ></DataList> -->
          <!-- @showAlertDeleteApplicationDialog="showAlertDeleteApplicationDialog"
          ></MemberDataList> -->
        </div>
        <!-- END PRIORITY 4: MEMBER -->

        <AppLogsDialog v-model="openAppLogsDialog" :logs="logs"></AppLogsDialog>

        <ConfirmationDialog
          v-model="showConfirmationDialog"
          title="Padam"
          content="Kesemua data dan fail yang berkaitan dengan permohonan ini akan dihapuskan daripada sistem myE-Wawasan.

          Anda pasti ingin memadam permohonan ini?"
          :showProceedButton="true"
          :showBackButton="true"
          proceedButton="Ya"
          backButton="Tidak"
          @onProceed="deleteApplication()"
        ></ConfirmationDialog>

        <ConfirmationDialog
          v-model="showConfirmationDialog2"
          title="Padam Tugasan"
          content="Petugas akan dipadam dari permohonan ini.

          Anda pasti ingin padam tugasan ini?"
          :showProceedButton="true"
          :showBackButton="true"
          proceedButton="Padam"
          backButton="Tidak"
          @onProceed="deleteAssignedApplication()"
        ></ConfirmationDialog>

        <RejectApplicationDialog
          v-model="showRejectApplicationDialog"
          title="Tolak semula permohonan"
          content="Permohonan ini akan dihantar semula kepada petugas (Sales) yang terlibat.

          Teruskan?"
          :application="application"
          :showProceedButton="true"
          :showBackButton="true"
          proceedButton="Teruskan"
          backButton="Tidak"
          @onProceed="rejectApplication"
        ></RejectApplicationDialog>

        <AssignApplicationDialog
          v-model="showAssignApplicationDialog"
          @onAssignApplication="onAssignApplication"
        ></AssignApplicationDialog>

        <ErrorMsgDialog
          v-model="openErrorMsgDialog"
          :title="msgERR.title"
          :modal="msgERR.modal"
          :content="msgERR.message"
          :showProceedButton="true"
          :showBackButton="false"
          :delayOnProceed="false"
          :isTypeError="true"
          proceedButton="OK"
        ></ErrorMsgDialog>

        <InfoMsgDialog
          v-model="openInfoMsgDialog"
          :title="msgINFO.title"
          :modal="msgINFO.modal"
          :content="msgINFO.message"
          :showProceedButton="true"
          :showBackButton="false"
          :delayOnProceed="false"
          proceedButton="OK"
          @onProceed="infoProceedOK()"
        ></InfoMsgDialog>

        <LockMsgDialog
          v-model="openLockMsgDialog"
          :title="msgINFO.title"
          :modal="msgINFO.modal"
          :content="msgINFO.message"
          :showProceedButton="true"
          :showBackButton="false"
          :delayOnProceed="false"
          proceedButton="OK"
          @onProceed="lockProceedOK()"
        ></LockMsgDialog>

        <NotificationDialog
          v-model="openNotificationDialog"
          title="'Lock' Permohonan Pembiayaan"
          proceedButton="Terima"
          backButton="Tidak Berminat"
          retryButton="Cuba Lagi"
          :showBackButton="true"
          :application="acceptDialog.application"
          :routeQuery="acceptDialog.query"
          :idleSec="40"
          :countDownSec="60"
          @onNotifyProceed="onNotifyProceed"
        ></NotificationDialog>

        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  SET_LAYOUT_CONFIG,
} from "@/core/services/store/config.module";
import {
  SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";
import {
  GET_USER,
  SAVE_FCM_TOKEN,
} from "@/core/services/store/user.module";
import {
  GET_APPLICATIONS,
  GET_APPLICATIONS_BY_PAGE,
  GET_APPS_ROLE_MEMBER,
  GET_APPLICATION_BY_PAGE,
  SET_APPLICATIONS,
  SET_PAGINATE_APPLICATIONS,
  SET_PAGED_APPS_ROLE_MEMBER,
  SET_PAGINATE_UNASSIGNED_APPLICATIONS,
  SET_PAGINATE_REMARK_APPLICATION,
  DELETE_APPLICATION,
  GET_ASSIGNED_APPLICATIONS_BY_BRANCH,
  GET_UNASSIGNED_APPLICATIONS_BY_BRANCH,
  GET_COMPLETE_APPLICATIONS,
  GET_APPROVED_APPLICATIONS,
  GET_APPROVED_APPLICATIONS_SO,
  GET_APPROVED_APPLICATIONS_CS,
  GET_REMARK_APPLICATIONS,
  SET_COMPLETE_APPLICATIONS,
  SET_APPROVED_APPLICATIONS,
  REJECT_APPLICATION,
  ASSIGN_APPLICATION,
  DELETE_ASSIGNED_APPLICATION,
  SET_UNASSIGNED_APPLICATIONS,
} from "@/core/services/store/application.module";
import {
  ADD_APP_LOG,
  GET_APP_LOGS,
  SET_APP_LOGS,
} from "@/core/services/store/applogs.module";
import {
  GET_APPLICANT_PRELOCK,
  GET_APPLICANT_CONFIRMLOCK,
  GET_APPLICANT_OF_TOKENID,
  GET_APPLICANT_UNDOLOCK,
} from "@/core/services/store/redirector.module";
import {
  GET_PENDING_APPLICATION_BATCHES,
  SET_PENDING_APPLICATION_BATCHES,
} from "@/core/services/store/approvedapplication.module";
import { errorMessages, formatAddress } from "@/core/services/helper.service";
import {
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS,
} from "@/core/services/store/notification.module";
// import DataList                from "@/view/pages/widgets/DataList.vue";
import MemberDataList          from "@/view/pages/widgets/member/DataList.vue";
import SalesOnlineDataList     from "@/view/pages/widgets/sales-online/DataList.vue";
import ProcessingDataList      from "@/view/pages/widgets/processing/DataList.vue";
import BranchManagerDataList   from "@/view/pages/widgets/branch-manager/DataList.vue";
import BatchList               from "@/view/pages/widgets/approver/BatchList.vue";

import AppLogsDialog           from "@/view/pages/widgets/AppLogsDialog.vue";
import RejectApplicationDialog from "@/view/pages/widgets/processing/RejectApplicationDialog.vue";
import AssignApplicationDialog from "@/view/pages/widgets/processing/AssignApplicationDialog.vue";
import LockMsgDialog           from "@/view/pages/widgets/MessageDialog.vue";
import NotificationDialog      from "@/view/pages/widgets/NotificationDialog.vue";

export default {
  name: "Dashboard",
  components: {
    // DataList,
    MemberDataList,
    BranchManagerDataList,
    SalesOnlineDataList,
    ProcessingDataList,
    AppLogsDialog,
    RejectApplicationDialog,
    AssignApplicationDialog,
    BatchList,
    LockMsgDialog,
    NotificationDialog,
  },
  data() {
    return {
      isGuest: false,
      // userId: null,
      showConfirmationDialog: false,
      showConfirmationDialog2: false,
      showRejectApplicationDialog: false,
      showAssignApplicationDialog: false,
      application: {},
      overlay: true,
      // Error Popup
      openErrorMsgDialog: false,
      msgERR: {
        title: "Ralat",
        modal: false,
        message: "",
        nric: "",
        run: 0,
      },
      // Info Popup
      openInfoMsgDialog: false,
      openLockMsgDialog: false,

      alert1: {
        message: "Anda belum set Kata - laluan! Set sekarang untuk memastikan akaun anda lebih selamat.",
        button1Caption: "Set Sekarang",
        type: "warning",
        dismissable: true,
      },

      msgINFO: {
        title: "Info",
        modal: false,
        message: "",
        nric: "",
        run: 0,
      },
      snackbar: {
        visible: false,
        title: "",
        text: "",
        color: "",
        icon: "",
      },
      openNotificationDialog: false,
      localAcceptDialog: {
        title: "",
        query: "",
        application: {
          id: 0,
          full_name: "",
          home_address: "",
          employer_name: "",
          employer_type_name: "",
          financing_req_amount: 0.0,
        },
      },
      infoProceedOKTicker: null,
      openAppLogsDialog: false,

    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "dashboardAllowed",
      "applications",
      "pagedAppsRoleMember",
      "pagedAppsRoleSalesAssistable",
      "paginateApplications",
      "completeApplications",
      "approvedApplications",
      "notifications",
      "paginateRemarkApplications",
      "paginateUnassignedApplications",
      "pendingApplicationBatches",
      "unassignedApplications",
      "logs",
    ]),

    isSales() {
      return !!(this.currentUserRoles[0] == 3);
    },

    isQryACT_AcceptApp() {
      return !!(this.$route.query.act == "accept_application");
    },

    isQryACT_AcceptFrm() {
      return !!(this.$route.query.act == "accept_form");
    },

    isQryACT_HelpApp() {
      return !!(this.$route.query.act == "help_application");
    },

    isQryACT_ViewApp() {
      return !!(this.$route.query.act == "view_application");
    },

    isInArray: function () {
      return this.currentUserRoles.includes(value);
    },

    acceptDialog: {
      get() {
        return this.localAcceptDialog
      },
      set(value) {
        this.localAcceptDialog = value;
      }
    },

    totalApplications() {
      return (
        this.paginateApplications?.data?.length +
        this.completeApplications?.data?.length
      );
    },

    totalCompletedApplication() {
      return isNaN(this.completeApplications?.data?.length) ? 0 : this.completeApplications?.data?.length;
    },

    isShowAlert1() {
      return !!(!this.currentUser.pwd_enabled && this.currentUser.email_verified);
    },
  },

  async mounted() {
    try {
      this.openOverlay();
      // Get logged in user details and its role(s)
      await this.$store.dispatch(GET_USER);
      await this.$store.dispatch(GET_NOTIFICATIONS);

      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);

      if (this.$route.query.params == "complete_application") {
        this.snackbar.visible = true;
        this.snackbar.title = this.$route.query.title;
        this.snackbar.text = this.$route.query.text;
        this.snackbar.color = "green lighten-1";
        this.snackbar.icon = "mdi-check-circle";
      }

      //
      // For Sales Online with redirected requests for Accepting Application
      //
      if (this.isSales && this.isQryACT_HelpApp) {
        await this.$store.dispatch(GET_APPLICANT_PRELOCK, this.$route.query)
          .then(async (resp1) => {
            if (resp1.data.success) {
              await this.$store.dispatch(GET_APPLICANT_OF_TOKENID, this.$route.query)
                .then((resp) => {
                  const data = resp.data.data;
                  if (resp.data.success) {
                    this.acceptDialog.application.id                   = data.app.id;
                    this.acceptDialog.application.full_name            = data.app.full_name;
                    this.acceptDialog.application.home_address         = data.app.home_address;
                    this.acceptDialog.application.employer_name        = data.app.employer_name;
                    this.acceptDialog.application.employer_location    = data.app.employer_location;
                    this.acceptDialog.application.employer_type_name   = data.app.employer_type_name;
                    this.acceptDialog.application.financing_req_amount = data.app.financing_req_amount;
                    this.acceptDialog.query                            = this.$route.query;
                    this.dialogNotificationOpen();
                  }
                  else {
                    this.clearRouteQuery();
                    this.promptError(resp.data.message);
                  }
                });
            }
            else {
              switch (resp1.data.errcode) {
                case "already_self_locked_previously":
                  this.clearRouteQuery();
                  this.promptError(
                    resp1.data.message,
                    "Anda sedang 'Lock' permohonan ini"
                  );
                  break;

                default:
                  this.clearRouteQuery();
                  this.promptError(
                    resp1.data.message,
                    "Gagal Mendapatkan Permohonan"
                  );
              }
            }
          })
          .finally(() => {
            // this.clearRouteQuery();
          });
      }
      else {
        this.$router.replace({
          name: this.$route.name,
        }).catch(() => {});
      }

      // For Customer Service with redirected requests for Accepting Assistance Requests
      // if (this.isSales && this.isQryACT_ViewApp) {
      //   await this.$store.dispatch(GET_APPLICANT_PRELOCK, this.$route.query)
      //     .then(async (resp1) => {
      //       if (resp1.data.success) {
      //         await this.$store.dispatch(GET_APPLICANT_OF_TOKENID, this.$route.query)
      //           .then(async (resp) => {
      //             const data = resp.data.data;
      //             if (resp.data.success) {
      //               this.onConfirmAssignAssistance();
      //             }
      //             else {
      //               const _context = {
      //                 sub: this.$route.query.sub,
      //                 immediate: true,
      //               };
      //               await this.$store.dispatch(GET_APPLICANT_UNDOLOCK, _context);
      //               this.clearRouteQuery();
      //               this.promptError(resp.data.message);
      //             }
      //           });
      //       }
      //       else {
      //         switch (resp1.data.errcode) {
      //           case "already_self_locked_previously":
      //             this.clearRouteQuery();
      //             this.promptError(
      //               resp1.data.message,
      //               "Anda sedang 'Lock' permohonan ini"
      //             );
      //             break;

      //           default:
      //             this.clearRouteQuery();
      //             this.promptError(
      //               resp1.data.message,
      //               "Gagal Mendapatkan Permohonan"
      //             );
      //         }
      //       }
      //     })
      //     .finally(() => {
      //       // this.clearRouteQuery();
      //     });
      // }
      // else {
      //   this.$router.replace({ name: this.$route.name }).catch(() => {});
      // }

      this.$messaging
        .getToken({
          // vapidKey: "BJqDBXujAggfTSnB4-Tyx-vFB9JtKqfjcBnBnFh-guwRvyDZy019sIsyvtcwonZWjizOftVRL93fHmvTV5oU3lA",
          vapidKey:
            "BI6BgN1qMrRrueFRCiTDDckMSOCtC1yveEAJEIwFS0yT_draBHz5MMMuTgUNvd-ZfR_wMbD92o6d6qmEVmuMWc8",
        })
        .then((currentToken) => {
          if (currentToken) {
            this.$store.dispatch(SAVE_FCM_TOKEN, { token: currentToken });
          }
          else {
          }
        })
        .catch((err) => {});

      if (
        this.currentUser.stats.apps == 0 &&
        this.currentUserRoles.includes(6)
      ) {
        this.$router.push("/form");
      }

      // if (this.currentUserRoles.includes(4) == true) {
      //   await this.$store
      //     .dispatch(GET_ASSIGNED_APPLICATIONS_BY_BRANCH)
      //     .then((resp) => {
      //       this.$store.commit(SET_APPLICATIONS, resp.data.data.data);
      //       this.$store.commit(SET_PAGINATE_APPLICATIONS, resp.data.data);
      //       // console.log(this.applications);
      //     });
      //   await this.$store
      //     .dispatch(GET_UNASSIGNED_APPLICATIONS_BY_BRANCH)
      //     .then((resp) => {
      //       this.$store.commit(
      //         SET_UNASSIGNED_APPLICATIONS,
      //         resp.data.data.data
      //       );
      //       this.$store.commit(
      //         SET_PAGINATE_UNASSIGNED_APPLICATIONS,
      //         resp.data.data
      //       );
      //     });
      // } else
      if (this.currentUserRoles.includes(7) == true) {
        await this.$store.dispatch(GET_PENDING_APPLICATION_BATCHES)
          .then((resp) => {
            this.$store.commit(
              SET_PENDING_APPLICATION_BATCHES,
              resp.data.data
            );
          });
      }
      else if (this.currentUserRoles.includes(3) == true) {
        await this.$store.dispatch(GET_APPLICATIONS)
          .then((resp) => {
            this.$store.commit(SET_PAGINATE_APPLICATIONS, resp.data.data);
            this.$store.commit(SET_APPLICATIONS, resp.data.data.data);
          });

        await this.$store.dispatch(GET_COMPLETE_APPLICATIONS)
          .then((resp) => {
            if (resp.data.success == true) {
              this.$store.commit(SET_COMPLETE_APPLICATIONS, resp.data.data);
            }
          });

        await this.$store.dispatch(GET_APPROVED_APPLICATIONS_CS)
          .then((resp) => {
            if (resp.data.success == true) {
              this.$store.commit(SET_APPROVED_APPLICATIONS, resp.data.data);
            }
          });
      }
      else if (this.currentUserRoles.includes(2) == true) {
        await this.$store.dispatch(GET_APPLICATIONS)
          .then((resp) => {
            this.$store.commit(SET_PAGINATE_APPLICATIONS, resp.data.data);
            this.$store.commit(SET_APPLICATIONS, resp.data.data.data);
          });

        await this.$store.dispatch(GET_REMARK_APPLICATIONS)
          .then((resp) => {
            this.$store.commit(
              SET_PAGINATE_REMARK_APPLICATION,
              resp.data.data
            );
          });

        await this.$store.dispatch(GET_APPROVED_APPLICATIONS)
          .then((resp) => {
            this.$store.commit(SET_APPROVED_APPLICATIONS, resp.data.data);
          });
      }
      else {
        await this.$store.dispatch(GET_APPS_ROLE_MEMBER);
      }
    } catch (err) {
      this.promptError(err?.message ?? "Unknown Error: " + err);
    } finally {
      setTimeout(() => {
        this.overlay = false;
      }, 100);
    }
  },
  methods: {
    dialogNotificationOpen() {
      this.openNotificationDialog = true;
    },

    dialogNotificationClose() {
      this.openNotificationDialog = false;
    },

    async paginate(url) {
      this.openOverlay();
      await this.$store
        .dispatch(GET_APPLICATIONS_BY_PAGE, url)
        .then(async (response) => {
          this.$store.commit(SET_PAGINATE_APPLICATIONS, response.data.data);
          this.$store.commit(SET_PAGED_APPS_ROLE_MEMBER, response.data.data);
        });
      this.closeOverlay();
    },

    showAlertDeleteApplicationDialog(value) {
      this.application = value;
      this.showConfirmationDialog = true;
    },

    showAlertRejectApplicationDialog(application) {
      this.application = application;
      this.showRejectApplicationDialog = true;
    },

    showDeleteAssignedApplicationConfirmationDialog(value) {
      this.application = value;
      this.showConfirmationDialog2 = true;
    },

    showAlertAssignApplicationDialog(application) {
      this.application = application;
      this.showAssignApplicationDialog = true;
    },

    onAssignApplication(salesExecId) {
      if (this.currentUserRoles.includes(4) == true) {
        const _context = {
          user_id: salesExecId,
          application_id: this.application.id,
        };

        this.$store.dispatch(ASSIGN_APPLICATION, _context)
          .then(async (response) => {
            if (response.data.success == true) {
              await this.$store.dispatch(GET_ASSIGNED_APPLICATIONS_BY_BRANCH)
                .then((response) => {
                  this.$store.commit(SET_APPLICATIONS, response.data.data.data);
                });
              await this.$store.dispatch(GET_UNASSIGNED_APPLICATIONS_BY_BRANCH)
                .then((response) => {
                  this.$store.commit(
                    SET_UNASSIGNED_APPLICATIONS,
                    response.data.data.data
                  );
                });
              this.showAssignApplicationDialog = false;
            }
            else {
              this.promptError(response?.data?.message);
            }
          })
          .catch((err) => {
            this.promptError(response?.data?.message);
          });
      }
    },

    async showAppLogsDialog(application) {
      this.application = application;
      const _context = {
        application_id: this.application.id,
      };

      if (this.application) {
        await this.$store.dispatch(GET_APP_LOGS, _context)
          .then((response) => {
            if (response.data.success == true) {
              this.$store.commit(SET_APP_LOGS, response.data.data);
              this.openAppLogsDialog = true;
            }
          });
      }
    },

    deleteApplication() {
      this.$store.dispatch(DELETE_APPLICATION, this.application)
        .then((result) => {
          this.showConfirmationDialog = false;
        })
        .catch((err) => {
        });
    },

    deleteAssignedApplication() {
      const _context = {
        user_id: this.application.sales_executive.user_id,
        application_id: this.application.id,
      };

      this.$store.dispatch(DELETE_ASSIGNED_APPLICATION, _context)
        .then(async (response) => {
          if (response.data.success == true) {
            await this.$store.dispatch(GET_ASSIGNED_APPLICATIONS_BY_BRANCH)
              .then((response) => {
                this.$store.commit(SET_APPLICATIONS, response.data.data.data);
                // xxconsole.log(this.applications);
              });

            await this.$store.dispatch(GET_UNASSIGNED_APPLICATIONS_BY_BRANCH)
              .then((response) => {
                this.$store.commit(
                  SET_UNASSIGNED_APPLICATIONS,
                  response.data.data.data
                );
              });
            this.showConfirmationDialog2 = false;
          }
        });
    },

    rejectApplication(remark) {
      if (this.currentUserRoles.includes(2) == true) {
        // this.application.remark = _remark;
        const _context = {
          application_id: this.application.id,
          description: remark,
        };

        this.$store.dispatch(REJECT_APPLICATION, _context)
          .then((response) => {
            if (response.data.success == true) {
              this.$store.dispatch(GET_APPLICATIONS)
                .then((response) => {
                  this.$store.commit(SET_APPLICATIONS, response.data.data.data);
                });
            }
          });
        this.showRejectApplicationDialog = false;
      }
    },

    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },

    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    goToChangePassword() {
      this.$router.push({ name: "ChangePassword" });
    },

    clearRouteQuery() {
      this.$router.replace({ name: this.$route.name }).catch(() => {});
    },

    promptError(message, title = null) {
      this.msgERR.message = errorMessages(message);
      if (title) {
        this.msgERR.title = title;
      }
      this.openErrorMsgDialog = true;
    },

    promptInfo(message, title = null, modal = false) {
      this.msgINFO.message = message;
      this.msgINFO.modal = modal;
      if (title) {
        this.msgINFO.title = title;
      }
      this.openInfoMsgDialog = true;
    },

    promptLock(message, title = null, modal = false) {
      this.msgINFO.message = message;
      this.msgINFO.modal = modal;
      if (title) {
        this.msgINFO.title = title;
      }
      this.openLockMsgDialog = true;
    },

    infoProceedOK() {
      //
    },

    lockProceedOK() {
      this.$router.push("/edit/" + this.$route.query.sub);
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 200);
    },

    async onNotifyProceed() {
      // await this.$store.dispatch(GET_APPLICANT_CONFIRMLOCK, this.$route.query)
      //   .then(async (resp1) => {
      //     if (resp1.data.success) {
      //       this.promptLock(
      //         "Permohonan berjaya diterima dan 'Lock'. Sila berhubung terus dengan pemohon untuk tindakan seterusnya.",
      //         "Berjaya",
      //         true
      //       );
            await this.$store.dispatch(GET_APPLICATIONS)
              .then((resp) => {
                this.$store.commit(SET_PAGINATE_APPLICATIONS, resp.data.data);
                this.$store.commit(SET_APPLICATIONS, resp.data.data.data);
              });
          // }
          // else {
          //   this.promptError(resp1.data.message, "Tidak Berjaya");
          // }
          this.clearRouteQuery();
        // });
      this.dialogNotificationClose();
    },

    async onConfirmAssignAssistance() {
      await this.$store.dispatch(GET_APPLICANT_CONFIRMLOCK, this.$route.query)
        .then(async (resp1) => {
          if (resp1.data.success) {
            this.promptLock(
              "Anda telah menerima tugas untuk membantu permohonan ini. Sila berhubung terus dengan pemohon untuk tindakan seterusnya.",
              "Berjaya",
              true
            );
            await this.$store.dispatch(GET_APPLICATIONS)
              .then((resp) => {
                this.$store.commit(SET_PAGINATE_APPLICATIONS, resp.data.data);
                this.$store.commit(SET_APPLICATIONS, resp.data.data.data);
              });
          }
          else {
            this.promptError(resp1.data.message, "Tidak Berjaya");
          }
          this.clearRouteQuery();
        });
      this.dialogNotificationClose();
    },
  },
};
</script>
<style scoped>
.body {
  /* background-color: #f3f6f9; */
  background-color: #f5f5f9;
}
.theme--light.v-application {
  background: #f5f5f9;
}
</style>