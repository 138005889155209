var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DataList", {
        attrs: {
          title: "Senarai Permohonan",
          appsData: _vm.pagedAppsRoleMember,
          appsConfig: _vm.memberHeaders,
          detailButton: true,
        },
        on: {
          onDetail: function ($event) {
            return _vm.goToEditPage($event)
          },
        },
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          modal: _vm.msgERR.modal,
          title: _vm.msgERR.title,
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.msgERR.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgERR, "show", $$v)
          },
          expression: "msgERR.show",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          modal: _vm.msgINFO.modal,
          title: _vm.msgINFO.title,
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        model: {
          value: _vm.msgINFO.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgINFO, "show", $$v)
          },
          expression: "msgINFO.show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }