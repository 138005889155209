var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-snackbar",
            {
              attrs: { color: _vm.snackbar.color, top: "" },
              model: {
                value: _vm.snackbar.visible,
                callback: function ($$v) {
                  _vm.$set(_vm.snackbar, "visible", $$v)
                },
                expression: "snackbar.visible",
              },
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    "no-gutters": "",
                    align: "center",
                    justify: "center",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.snackbar.icon))])],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c("div", { staticClass: "mx-3" }, [
                      _c(
                        "p",
                        { staticClass: "snackbar-title font-weight-bold mb-0" },
                        [_vm._v(" " + _vm._s(_vm.snackbar.title) + " ")]
                      ),
                      _c("p", { staticClass: "snackbar-subtitle mb-0" }, [
                        _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                      ]),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.snackbar.visible = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
            [
              _c("AlertActionable", {
                attrs: {
                  alertMessage: _vm.alert1.message,
                  alertType: _vm.alert1.type,
                  button1Caption: _vm.alert1.button1Caption,
                  dismissable: _vm.alert1.dismissable,
                  outlined: false,
                },
                on: {
                  onAction1: function ($event) {
                    return _vm.goToChangePassword()
                  },
                },
                model: {
                  value: _vm.isShowAlert1,
                  callback: function ($$v) {
                    _vm.isShowAlert1 = $$v
                  },
                  expression: "isShowAlert1",
                },
              }),
              _vm.currentUserRoles.includes(7)
                ? _c(
                    "div",
                    [
                      _c("BatchList", {
                        attrs: {
                          pendingApplicationBatches:
                            _vm.pendingApplicationBatches,
                        },
                        on: {
                          showAlertDeleteApplicationDialog:
                            _vm.showAlertDeleteApplicationDialog,
                        },
                      }),
                    ],
                    1
                  )
                : _vm.currentUserRoles.includes(4)
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "4",
                                lg: "4",
                                xl: "4",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card card-custom gutter-b red lighten-3",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header border-0 py-5",
                                    },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "card-title align-items-start flex-column",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "card-label font-weight-bolder",
                                            },
                                            [_vm._v("Jumlah Permohonan")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "card-body py-0" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "card-title align-items-start flex-column text-right",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "card-label font-weight-bolder",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.unassignedApplications
                                                  .length +
                                                  _vm.applications.length
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "4",
                                lg: "4",
                                xl: "4",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card card-custom gutter-b green lighten-3",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header border-0 py-5",
                                    },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "card-title align-items-start flex-column",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "card-label font-weight-bolder",
                                            },
                                            [_vm._v("Permohonan Lengkap")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "card-body py-0" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "card-title align-items-start flex-column text-right",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "card-label font-weight-bolder",
                                          },
                                          [_vm._v("10")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "4",
                                lg: "4",
                                xl: "4",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card card-custom gutter-b yellow lighten-3",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header border-0 py-5",
                                    },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "card-title align-items-start flex-column",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "card-label font-weight-bolder",
                                            },
                                            [_vm._v("Permohonan Tidak Lengkap")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "card-body py-0" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "card-title align-items-start flex-column text-right",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "card-label font-weight-bolder",
                                          },
                                          [_vm._v("2")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                                xl: "12",
                              },
                            },
                            [
                              _c("BranchManagerDataList", {
                                attrs: {
                                  applications: _vm.paginateApplications,
                                  unassignedApplications:
                                    _vm.paginateUnassignedApplications,
                                },
                                on: {
                                  paginate: _vm.paginate,
                                  showAlertAssignApplicationDialog:
                                    _vm.showAlertAssignApplicationDialog,
                                  showDeleteAssignedApplicationConfirmationDialog:
                                    _vm.showDeleteAssignedApplicationConfirmationDialog,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.currentUserRoles.includes(2)
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                                xl: "12",
                              },
                            },
                            [_c("ProcessingDataList")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.currentUserRoles.includes(3)
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                                xl: "12",
                              },
                            },
                            [_c("SalesOnlineDataList")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.currentUserRoles.includes(5)
                ? _c("div", [_c("MemberDataList")], 1)
                : _vm._e(),
              _c("AppLogsDialog", {
                attrs: { logs: _vm.logs },
                model: {
                  value: _vm.openAppLogsDialog,
                  callback: function ($$v) {
                    _vm.openAppLogsDialog = $$v
                  },
                  expression: "openAppLogsDialog",
                },
              }),
              _c("ConfirmationDialog", {
                attrs: {
                  title: "Padam",
                  content:
                    "Kesemua data dan fail yang berkaitan dengan permohonan ini akan dihapuskan daripada sistem myE-Wawasan.\n\n        Anda pasti ingin memadam permohonan ini?",
                  showProceedButton: true,
                  showBackButton: true,
                  proceedButton: "Ya",
                  backButton: "Tidak",
                },
                on: {
                  onProceed: function ($event) {
                    return _vm.deleteApplication()
                  },
                },
                model: {
                  value: _vm.showConfirmationDialog,
                  callback: function ($$v) {
                    _vm.showConfirmationDialog = $$v
                  },
                  expression: "showConfirmationDialog",
                },
              }),
              _c("ConfirmationDialog", {
                attrs: {
                  title: "Padam Tugasan",
                  content:
                    "Petugas akan dipadam dari permohonan ini.\n\n        Anda pasti ingin padam tugasan ini?",
                  showProceedButton: true,
                  showBackButton: true,
                  proceedButton: "Padam",
                  backButton: "Tidak",
                },
                on: {
                  onProceed: function ($event) {
                    return _vm.deleteAssignedApplication()
                  },
                },
                model: {
                  value: _vm.showConfirmationDialog2,
                  callback: function ($$v) {
                    _vm.showConfirmationDialog2 = $$v
                  },
                  expression: "showConfirmationDialog2",
                },
              }),
              _c("RejectApplicationDialog", {
                attrs: {
                  title: "Tolak semula permohonan",
                  content:
                    "Permohonan ini akan dihantar semula kepada petugas (Sales) yang terlibat.\n\n        Teruskan?",
                  application: _vm.application,
                  showProceedButton: true,
                  showBackButton: true,
                  proceedButton: "Teruskan",
                  backButton: "Tidak",
                },
                on: { onProceed: _vm.rejectApplication },
                model: {
                  value: _vm.showRejectApplicationDialog,
                  callback: function ($$v) {
                    _vm.showRejectApplicationDialog = $$v
                  },
                  expression: "showRejectApplicationDialog",
                },
              }),
              _c("AssignApplicationDialog", {
                on: { onAssignApplication: _vm.onAssignApplication },
                model: {
                  value: _vm.showAssignApplicationDialog,
                  callback: function ($$v) {
                    _vm.showAssignApplicationDialog = $$v
                  },
                  expression: "showAssignApplicationDialog",
                },
              }),
              _c("ErrorMsgDialog", {
                attrs: {
                  title: _vm.msgERR.title,
                  modal: _vm.msgERR.modal,
                  content: _vm.msgERR.message,
                  showProceedButton: true,
                  showBackButton: false,
                  delayOnProceed: false,
                  isTypeError: true,
                  proceedButton: "OK",
                },
                model: {
                  value: _vm.openErrorMsgDialog,
                  callback: function ($$v) {
                    _vm.openErrorMsgDialog = $$v
                  },
                  expression: "openErrorMsgDialog",
                },
              }),
              _c("InfoMsgDialog", {
                attrs: {
                  title: _vm.msgINFO.title,
                  modal: _vm.msgINFO.modal,
                  content: _vm.msgINFO.message,
                  showProceedButton: true,
                  showBackButton: false,
                  delayOnProceed: false,
                  proceedButton: "OK",
                },
                on: {
                  onProceed: function ($event) {
                    return _vm.infoProceedOK()
                  },
                },
                model: {
                  value: _vm.openInfoMsgDialog,
                  callback: function ($$v) {
                    _vm.openInfoMsgDialog = $$v
                  },
                  expression: "openInfoMsgDialog",
                },
              }),
              _c("LockMsgDialog", {
                attrs: {
                  title: _vm.msgINFO.title,
                  modal: _vm.msgINFO.modal,
                  content: _vm.msgINFO.message,
                  showProceedButton: true,
                  showBackButton: false,
                  delayOnProceed: false,
                  proceedButton: "OK",
                },
                on: {
                  onProceed: function ($event) {
                    return _vm.lockProceedOK()
                  },
                },
                model: {
                  value: _vm.openLockMsgDialog,
                  callback: function ($$v) {
                    _vm.openLockMsgDialog = $$v
                  },
                  expression: "openLockMsgDialog",
                },
              }),
              _c("NotificationDialog", {
                attrs: {
                  title: "'Lock' Permohonan Pembiayaan",
                  proceedButton: "Terima",
                  backButton: "Tidak Berminat",
                  retryButton: "Cuba Lagi",
                  showBackButton: true,
                  application: _vm.acceptDialog.application,
                  routeQuery: _vm.acceptDialog.query,
                  idleSec: 40,
                  countDownSec: 60,
                },
                on: { onNotifyProceed: _vm.onNotifyProceed },
                model: {
                  value: _vm.openNotificationDialog,
                  callback: function ($$v) {
                    _vm.openNotificationDialog = $$v
                  },
                  expression: "openNotificationDialog",
                },
              }),
              _c(
                "v-overlay",
                { attrs: { value: _vm.overlay } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }