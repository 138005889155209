<template>
  <!--begin::Advance Table Widget 10-->
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Senarai Batch</span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-0">
      <!--begin::Table-->
      <div class="table-responsive table-hover">
        <table class="table table-head-custom table-vertical-center">
          <thead>
            <tr class="text-center">
              <th class="pl-0">No.</th>
              <th>
                Tarikh Mohon
                <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg-->
              </th>
              <th class="pl-0">No. Batch</th>
              <th class="pl-0">Bilangan</th>
              <th class="pr-0">Tindakan</th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr class="text-center" v-for="(pendingApp, i) in pendingApplicationBatches.data" :key="pendingApp.id">
                <td class="pl-0">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-md">
                    {{ i + pendingApplicationBatches.from }}
                  </span>
                </td>
                <td>
                  <span class="text-dark-75 font-weight-bolder d-block font-size-md">
                    {{ pendingApp.batch_date }}
                  </span>
                </td>
                <td class="pl-0">
                  <span class="text-primary font-weight-bolder d-block font-size-md">
                    {{ pendingApp.batch_no }}
                  </span>
                </td>
                <td class="pl-0">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-md">
                    {{ pendingApp.pending + " " + "Permohonan" }}
                  </span>
                </td>
                <td class="pr-0">
                  <v-btn icon class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    @click="showByBatch(pendingApp)">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                      <inline-svg src="media/svg/icons/Files/File.svg"></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <nav>
        <ul class="pagination justify-content-end">
          <div v-for="link in pendingApplicationBatches.links" :key="link.label">
            <li v-bind:class="`page-item ${link.url == null ? 'disabled' : ''} ${
                link.active == true ? 'active' : ''
              }`">
              <a class="page-link" @click="paginate(link.url)" :aria-label="link.label">{{ decodeHtml(link.label) }}</a>
            </li>
          </div>
        </ul>
      </nav>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 10-->
</template>

<script>
export default {
  name: "BatchList",
  props: {
    pendingApplicationBatches: {},
  },
  data() {
    return {
      overlay: true,
    };
  },
  mounted() {
    this.closeOverlay();
  },
  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    setCheck(check) {
      if (check) {
        this.checked = check;
      } else {
        this.checked = false;
      }
    },

    showByBatch(batch) {
      this.$router.push({
        name: "ApplicationByBatch",
        params: { 
          detail: 1,
          batch_no: batch.batch_no,
          batch: batch 
        },
      });
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },
  },
};
</script>
