var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("StatusSummary", { attrs: { statuses: _vm.statuses } }),
      _c(
        "b-tabs",
        { attrs: { "active-nav-item-class": "font-weight-bold" } },
        [
          _vm.receivedHasData
            ? _c(
                "b-tab",
                {
                  attrs: { title: "Diterima", lazy: "" },
                  on: {
                    click: function ($event) {
                      return _vm.receivedTabSelect()
                    },
                  },
                },
                [
                  _c("DataList", {
                    attrs: {
                      title: "Senarai permohonan untuk diproses",
                      appsData: _vm.pagedAppsRoleProcRcvd,
                      appsConfig: _vm.headers,
                      detailButton: true,
                      deleteButton: true,
                    },
                    on: {
                      onDetail: function ($event) {
                        return _vm.goToEditPage($event)
                      },
                      onDelete: function ($event) {
                        return _vm.goToDeleteApp($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.toApproveHasData
            ? _c(
                "b-tab",
                {
                  attrs: { title: "Menunggu Kelulusan", lazy: "" },
                  on: {
                    click: function ($event) {
                      return _vm.toApprovedTabSelect()
                    },
                  },
                },
                [
                  _c("DataList", {
                    attrs: {
                      title:
                        "Senarai permohonan yang telah diproses dan dihantar untuk kelulusan",
                      appsData: _vm.pagedAppsRoleProcToAppr,
                      appsConfig: _vm.headers,
                      detailButton: true,
                      deleteButton: true,
                    },
                    on: {
                      onDetail: function ($event) {
                        return _vm.goToEditPage($event)
                      },
                      onDelete: function ($event) {
                        return _vm.goToDeleteApp($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.remarkedHasData
            ? _c(
                "b-tab",
                {
                  attrs: { title: "Dengan Remarks", lazy: "" },
                  on: {
                    click: function ($event) {
                      return _vm.remarkedTabSelect()
                    },
                  },
                },
                [
                  _c("DataList", {
                    attrs: {
                      title: "Senarai permohonan untuk disemak semula",
                      appsData: _vm.pagedAppsRoleProcRemark,
                      appsConfig: _vm.headers,
                      detailButton: true,
                      deleteButton: true,
                    },
                    on: {
                      onDetail: function ($event) {
                        return _vm.goToEditPage($event)
                      },
                      onDelete: function ($event) {
                        return _vm.goToDeleteApp($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.approvedHasData
            ? _c(
                "b-tab",
                {
                  attrs: { title: "Diluluskan", lazy: "" },
                  on: {
                    click: function ($event) {
                      return _vm.approvedTabSelect()
                    },
                  },
                },
                [
                  _c("DataList", {
                    attrs: {
                      title: "Senarai permohonan yang telah lulus",
                      appsData: _vm.pagedAppsRoleProcApprvd,
                      appsConfig: _vm.headers,
                      detailButton: true,
                      deleteButton: true,
                    },
                    on: {
                      onDetail: function ($event) {
                        return _vm.goToEditPage($event)
                      },
                      onDelete: function ($event) {
                        return _vm.goToDeleteApp($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-tab",
            {
              attrs: { title: "Ditolak", lazy: "" },
              on: {
                click: function ($event) {
                  return _vm.rejectedTabSelect()
                },
              },
            },
            [
              _c("DataList", {
                attrs: {
                  title: "Senarai permohonan yang ditolak",
                  appsData: _vm.pagedAppsRoleProcReject,
                  appsConfig: _vm.headers,
                  detailButton: true,
                  deleteButton: true,
                },
                on: {
                  onDetail: function ($event) {
                    return _vm.goToEditPage($event)
                  },
                  onDelete: function ($event) {
                    return _vm.goToDeleteApp($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          modal: _vm.msgERR.modal,
          title: _vm.msgERR.title,
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.msgERR.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgERR, "show", $$v)
          },
          expression: "msgERR.show",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          modal: _vm.msgINFO.modal,
          title: _vm.msgINFO.title,
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        on: {
          onProceed: function ($event) {
            return _vm.infoProceedOK()
          },
        },
        model: {
          value: _vm.msgINFO.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgINFO, "show", $$v)
          },
          expression: "msgINFO.show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }