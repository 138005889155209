<template>
  <div>
    <b-tabs active-nav-item-class="font-weight-bold">
      <b-tab title="Tiada petugas" lazy>
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder"
                >Senarai Permohonan
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive table-hover">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr class="text-center">
                    <th class="pl-0">No.</th>
                    <th class="pl-0">Cawangan</th>
                    <th class="pl-0">No. Rujukan</th>
                    <th>
                      Tarikh Permohonan
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg-->
                    </th>
                    <th class="pl-0">Jenis Permohonan</th>
                    <th>Status</th>
                    <th>Nota</th>
                    <th>Ditugaskan</th>
                    <th class="pr-0 text-right" style="min-width: 100px">
                      Tindakan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr
                      class="text-center"
                      v-for="(
                        unassignedApplication, i
                      ) in unassignedApplications.data"
                      :key="unassignedApplication.id"
                    >
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          i + 1
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bolder
                            h-25
                            label-light-primary
                          "
                        >
                          {{ unassignedApplication.branch.name.toUpperCase() }}
                        </span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          unassignedApplication.ref_no
                        }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">{{
                          unassignedApplication.submitted_date | formatDateTime
                        }}</span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          unassignedApplication.application_type.name
                        }}</span>
                        <span class="text-muted font-weight-bold">{{
                          unassignedApplication.subtitle
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bold
                            h-25
                          "
                          v-bind:class="`label-light-${unassignedApplication.status.color_class}`"
                          >{{ unassignedApplication.status.name }}</span
                        >
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="font-weight-bolder d-block font-size-md"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 400px;
                              "
                            >
                              {{ unassignedApplication.remark }}
                            </div>
                          </template>
                          <span>{{ unassignedApplication.remark }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">
                          {{ unassignedApplication.sales_executive.user }}
                        </span>
                      </td>
                      <td class="pr-0 text-right">
                        <!-- <a
                    href="#"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary"> -->
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
                        <!-- <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      ></inline-svg> -->
                        <!--end::Svg Icon-->
                        <!-- </span>
                  </a> -->
                        <v-btn
                          icon
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                            mx-3
                          "
                          @click="goToEditPage(unassignedApplication)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                            <inline-svg
                              src="media/svg/icons/General/Visible.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </v-btn>
                        <v-btn
                          icon
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                          "
                          @click="
                            openAssignApplicationDialog(unassignedApplication)
                          "
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                            <inline-svg
                              src="media/svg/icons/Communication/Write.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <nav>
              <ul class="pagination justify-content-end">
                <div v-for="link in applications.links" :key="link.label">
                  <li
                    v-bind:class="`page-item ${
                      link.url == null ? 'disabled' : ''
                    } ${link.active == true ? 'active' : ''}`"
                  >
                    <a
                      class="page-link"
                      @click="paginate(link.url)"
                      :aria-label="link.label"
                      >{{ decodeHtml(link.label) }}</a
                    >
                  </li>
                </div>
              </ul>
            </nav>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </b-tab>
      <b-tab title="Sudah Ditugaskan">
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder"
                >Senarai Permohonan
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr class="text-center">
                    <th class="pl-0">No.</th>
                    <th class="pl-0">Cawangan</th>
                    <th class="pl-0">No. Rujukan</th>
                    <th>
                      Tarikh Permohonan
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg-->
                    </th>
                    <th class="pl-0">Jenis Permohonan</th>
                    <th>Status</th>
                    <th>Nota</th>
                    <th>Ditugaskan</th>
                    <th class="pr-0 text-right" style="min-width: 100px">
                      Tindakan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr
                      class="text-center"
                      v-for="(application, i) in applications.data"
                      :key="application.id"
                    >
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          i + applications.from
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bolder
                            h-25
                            label-light-primary
                          "
                        >
                          {{ application.branch.name.toUpperCase() }}
                        </span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.ref_no
                        }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.submitted_date | formatDateTime
                        }}</span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.application_type.name
                        }}</span>
                        <span class="text-muted font-weight-bold">{{
                          application.subtitle
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bold
                            h-25
                          "
                          v-bind:class="`label-light-${application.status.color_class}`"
                          >{{ application.status.nameMS | upperCase }}</span
                        >
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="font-weight-bolder d-block font-size-md"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 400px;
                              "
                            >
                              {{ application.remark }}
                            </div>
                          </template>
                          <span>{{ application.remark }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">
                          {{
                            application.sales_executive
                              ? application.sales_executive.user.full_name
                              : ""
                          }}
                        </span>
                      </td>
                      <td class="pr-0 text-right">
                        <!-- <a
                    href="#"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary"> -->
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
                        <!-- <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      ></inline-svg> -->
                        <!--end::Svg Icon-->
                        <!-- </span>
                  </a> -->
                        <v-btn
                          icon
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                            mx-3
                          "
                          @click="goToEditPage(application)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                            <inline-svg
                              src="media/svg/icons/General/Visible.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </v-btn>
                        <v-btn
                          icon
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                          "
                          @click="
                            openDeleteAssignedApplicationConfirmationDialog(
                              application
                            )
                          "
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                            <inline-svg
                              src="media/svg/icons/General/Trash.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <nav>
              <ul class="pagination justify-content-end">
                <div v-for="link in applications.links" :key="link.label">
                  <li
                    v-bind:class="`page-item ${
                      link.url == null ? 'disabled' : ''
                    } ${link.active == true ? 'active' : ''}`"
                  >
                    <a
                      class="page-link"
                      @click="paginate(link.url)"
                      :aria-label="link.label"
                      >{{ decodeHtml(link.label) }}</a
                    >
                  </li>
                </div>
              </ul>
            </nav>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: "BranchManagerAppList",
  props: {
    applications: {},
    unassignedApplications: {},
  },
  data() {
    return {};
  },
  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    setCheck(check) {
      if (check) {
        this.checked = check;
      } else {
        this.checked = false;
      }
    },
    paginate(url) {
      this.$emit("paginate", url);
    },
    goToEditPage(form) {
      this.$router.push({
        name: "EditForm",
        params: { id: form.id },
      });
    },

    openAssignApplicationDialog(application) {
      this.$emit("showAlertAssignApplicationDialog", application);
    },
    openDeleteAssignedApplicationConfirmationDialog(application) {
      this.$emit(
        "showDeleteAssignedApplicationConfirmationDialog",
        application
      );
    },
  },
};
</script>
