var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
            [
              _c(
                "v-row",
                _vm._l(_vm.localStatus, function (status) {
                  return _c(
                    "v-col",
                    {
                      key: status.id,
                      attrs: {
                        cols: "12",
                        sm: "12",
                        md: "6",
                        lg: "4",
                        xl: "4",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card card-custom gutter-b lighten-3",
                          class: status.class,
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card-header border-0 py-5" },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "card-title align-items-start flex-column",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "card-label font-weight-bolder",
                                    },
                                    [_vm._v(" " + _vm._s(status.title) + " ")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "card-body py-0" }, [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "card-title align-items-start flex-column text-right",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "card-label font-weight-bolder",
                                  },
                                  [_vm._v(" " + _vm._s(status.value) + " ")]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }