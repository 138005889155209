<template>
  <div>
    <DataList
      :title="'Senarai Permohonan'"
      :appsData="pagedAppsRoleMember"
      :appsConfig="memberHeaders"
      :detailButton="true"
      @onDetail="goToEditPage($event)"
    ></DataList>

    <v-overlay :value="overlay"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>

    <ErrorMsgDialog
      v-model="msgERR.show"
      :modal="msgERR.modal"
      :title="msgERR.title"
      :content="msgERR.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      :isTypeError="true"
      proceedButton="OK"
    ></ErrorMsgDialog>

    <InfoMsgDialog
      v-model="msgINFO.show"
      :modal="msgINFO.modal"
      :title="msgINFO.title"
      :content="msgINFO.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      proceedButton="OK"
    ></InfoMsgDialog>
      <!-- @onProceed="infoProceedOK()" -->

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  errorMessages,
  extractPagingInfoOnly,
} from "@/core/services/helper.service";

import {
  // GET_APPS_BY_URL,
  GET_APPS_ROLE_MEMBER,
  SET_PAGED_APPS_ROLE_MEMBER,
} from "@/core/services/store/application.module";

import DataList from "@/view/pages/widgets/DataList.vue";

export default {
  name: "ApplicationList",

  components: {
    DataList,
  },

  props: {
    applications: {},
  },

  data() {
    return {
      overlay: true,

      msgERR: {
        show: false,
        modal: false,
        title: "Ralat",
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        show: false,
        modal: false,
        title: "Info",
        message: "",
        nric: "",
        run: 0,
      },

      memberHeaders: [
        {
          text: "No. Rujukan",
          value: "ref_no",
          type: String,
        },
        {
          text: "Kod Potongan",
          value: "loan_code",
          type: String,
        },
        {
          text: "Tarikh Mohon",
          value: "submitted_date",
          type: Date,
          filter: "formatDateTime",
        },
        {
          text: "Status",
          value: "status.nameMS",
          color: "status.color_class",
          type: String,
          filter: "upperCase",
          class_template: {
            "h-25": true,
            "label": true,
            "label-md": true,
            "label-inline": true,
            "font-weight-bold": true,
          },
        },
        {
          text: "Tarikh Kelulusan",
          value: "approval_date",
          type: Date,
          filter: "formatDate",
        },
        {
          text: "Tarikh Pembayaran",
          value: "disb_date",
          type: Date,
          filter: "formatDate",
        },
        {
          text: "Jumlah Dipohon",
          value: "member.financing_req_amount",
          type: Number,
          decimal: 0,
          filter: "fAmount",
          style: "white-space: nowrap;",
          class_template: {
            "text-primary": true,
          },
        },
        {
          text: "Jumlah Diluluskan",
          value: "approved_amount",
          type: Number,
          filter: "fAmount",
          style: "white-space: nowrap;",
          class_template: {
            "text-primary": true,
          },
        },
        {
          text: "Surat Tawaran",
          value: "media_name",
          type: String,
        },
        {
          text: "Nota",
          value: "remark",
          type: String,
          tooltip: {
            style: "white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 270px;",
            class: "text-dark-75",
          }
        },
      ],

    };
  },

  computed: {
    ...mapGetters([
      "pagedAppsRoleMember",
    ]),

  },

  async mounted() {
    //nfh-console.log(" IN: MOUNTED");
    await this.$store.dispatch(GET_APPS_ROLE_MEMBER);
    this.closeOverlay();
    //nfh-console.log("OUT: MOUNTED");
  },

  methods: {
    async loadPageData(page) {
      //nfh-console.log('loadPageData - page: ', page);
      this.openOverlay();
      if (page.success) {
        // this.$store.commit(SET_PAGED_APPS_ROLE_MEMBER, page.data);
      }
      else {
        this.promptError(page?.message, page?.error);
      }
      this.closeOverlay();
    },

    goToEditPage(data) {
      this.$router.push({
        name: "EditForm",
        params: { id: data.id },
      });
    },

    // deleteApplication(form) {
    //   this.$emit("showAlertDeleteApplicationDialog", form);
    // },

    promptError(message, title = null) {
      this.msgERR.message = errorMessages(message);
      this.msgERR.title = (title) ? title : "";
      this.msgERR.show = true;
    },

    promptInfo(message, title = null, modal = false) {
      this.msgINFO.message = message;
      this.msgINFO.title = (title) ? title : "";
      this.msgINFO.modal = modal;
      this.msgINFO.show = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 200);
    },

  },
};
</script>

<style scoped>
#loader {
  width: 100%;
  height: 100px;
}
</style>
