var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-custom gutter-b" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "card-body py-0" },
      [
        _c("div", { staticClass: "table-responsive table-hover" }, [
          _c(
            "table",
            { staticClass: "table table-head-custom table-vertical-center" },
            [
              _vm._m(1),
              _c(
                "tbody",
                [
                  _vm._l(
                    _vm.pendingApplicationBatches.data,
                    function (pendingApp, i) {
                      return _c(
                        "tr",
                        { key: pendingApp.id, staticClass: "text-center" },
                        [
                          _c("td", { staticClass: "pl-0" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-dark-75 font-weight-bolder d-block font-size-md",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      i + _vm.pendingApplicationBatches.from
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-dark-75 font-weight-bolder d-block font-size-md",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(pendingApp.batch_date) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("td", { staticClass: "pl-0" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-primary font-weight-bolder d-block font-size-md",
                              },
                              [_vm._v(" " + _vm._s(pendingApp.batch_no) + " ")]
                            ),
                          ]),
                          _c("td", { staticClass: "pl-0" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-dark-75 font-weight-bolder d-block font-size-md",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      pendingApp.pending + " " + "Permohonan"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "pr-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "btn btn-icon btn-light btn-hover-primary btn-sm mx-3",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showByBatch(pendingApp)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "svg-icon svg-icon-md svg-icon-primary",
                                    },
                                    [
                                      _c("inline-svg", {
                                        attrs: {
                                          src: "media/svg/icons/Files/File.svg",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ]
          ),
        ]),
        _c(
          "v-overlay",
          { attrs: { value: _vm.overlay } },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", size: "64" },
            }),
          ],
          1
        ),
        _c("nav", [
          _c(
            "ul",
            { staticClass: "pagination justify-content-end" },
            _vm._l(_vm.pendingApplicationBatches.links, function (link) {
              return _c("div", { key: link.label }, [
                _c(
                  "li",
                  {
                    class:
                      "page-item " +
                      (link.url == null ? "disabled" : "") +
                      " " +
                      (link.active == true ? "active" : ""),
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": link.label },
                        on: {
                          click: function ($event) {
                            return _vm.paginate(link.url)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.decodeHtml(link.label)))]
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header border-0 py-5" }, [
      _c("h3", { staticClass: "card-title align-items-start flex-column" }, [
        _c("span", { staticClass: "card-label font-weight-bolder text-dark" }, [
          _vm._v("Senarai Batch"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-center" }, [
        _c("th", { staticClass: "pl-0" }, [_vm._v("No.")]),
        _c("th", [_vm._v(" Tarikh Mohon ")]),
        _c("th", { staticClass: "pl-0" }, [_vm._v("No. Batch")]),
        _c("th", { staticClass: "pl-0" }, [_vm._v("Bilangan")]),
        _c("th", { staticClass: "pr-0" }, [_vm._v("Tindakan")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }