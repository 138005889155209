var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.displayRejectApplicationDialog,
            callback: function ($$v) {
              _vm.displayRejectApplicationDialog = $$v
            },
            expression: "displayRejectApplicationDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-6" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-card-title", { staticClass: "px-0 py-0" }, [
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "px-0 py-0" },
                        [
                          _c("p", { staticClass: "body-1" }, [
                            _vm._v(_vm._s(_vm.content)),
                          ]),
                          _c(
                            "ValidationObserver",
                            { ref: "remarkValidation" },
                            [
                              _c(
                                "v-form",
                                [
                                  _c("ValidationProvider", {
                                    attrs: { name: "Nota", rules: "required" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-textarea", {
                                              attrs: {
                                                label: "Nota",
                                                "error-messages": errors,
                                                outlined: "",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.remark,
                                                callback: function ($$v) {
                                                  _vm.remark = $$v
                                                },
                                                expression: "remark",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex flex-wrap justify-content-end align-items-center",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm.showBackButton !== false
                        ? _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeDialog()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.backButton)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.showProceedButton !== false
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                color: "primary",
                                loading: _vm.isLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onProceed()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.proceedButton)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }