<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-row>

          <v-col v-for="(status) in localStatus" :key="status.id" cols="12" sm="12" md="6" lg="4" xl="4">
            <div :class="status.class" class="card card-custom gutter-b lighten-3">
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder"> {{ status.title }} </span>
                </h3>
              </div>
              <div class="card-body py-0">
                <h1 class="card-title align-items-start flex-column text-right">
                  <span class="card-label font-weight-bolder">
                    {{ status.value }}
                  </span>
                </h1>
              </div>
            </div>
          </v-col>

        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  errorMessages,
} from "@/core/services/helper.service";

import {
  // FETCH_RAW_URL,
  SET_PAGED_APPS_ROLE_MEMBER,
} from "@/core/services/store/helper.module";

export default {
  name: "StatusSummaryDashboard",

  components: {
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    statuses: {
      type: Array,
      default: () => { return []; },
    },

  },

  watch: {
    statuses(newValue, oldValue) {
      this.checkStatusesData(newValue);
    }

  },

  data() {
    return {
      localStatus: [],
      overlay: true,

      msgERR: {
        show: false,
        modal: false,
        title: "Ralat",
        message: "",
        nric: "",
        run: 0,
      },
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
    ]),

    statusesExists() {
      return Array.isArray(this.statuses) && this.statuses.length > 0;
    }
  },

  methods: {
    checkStatusesData(status) {
      if (Array.isArray(status)) {
        status.forEach(el => {
          el.value = (typeof el.value == 'undefined' || el.value == null || el.value == "") ? "\xa0" : el.value;
        });
        this.localStatus = status;
      }
    }
  },

  mounted() {
    this.checkStatusesData(this.statuses);
  }
};
</script>
