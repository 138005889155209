<template>
  <div>
    <StatusSummary
      :statuses="statuses"
    ></StatusSummary>

    <b-tabs
      active-nav-item-class="font-weight-bold"
    >
      <b-tab
        title="Perlu Bantuan"
        lazy
      >
          <!-- :appsData="needHelpApps" -->
        <DataList
          :title="'Permohonan Perlu Bantuan'"
          :appsData="pagedAppsRoleSalesAssistable"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="false"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>

      <!-- <b-tab
        title="Diterima"
        lazy
      >
        <DataList
          :title="'Senarai Permohonan Diterima'"
          :appsData="receivedApps"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="false"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab> -->

      <b-tab
        title="Diproses"
        lazy
      >
          <!-- :appsData="completeApps" -->
        <DataList
          :title="'Senarai Permohonan Sedang Diproses'"
          :appsData="pagedAppsRoleSalesProcess"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="false"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>

      <b-tab
        title="Dengan Remarks"
        lazy
      >
          <!-- :appsData="completeApps" -->
        <DataList
          :title="'Senarai Permohonan Dengan Remarks'"
          :appsData="pagedAppsRoleSalesRemarked"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="false"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>

      <b-tab
        title="Diluluskan"
        lazy
      >
          <!-- :appsData="approvedApps" -->
        <DataList
          :title="'Senarai Permohonan Telah Lulus'"
          :appsData="pagedAppsRoleSalesApproved"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="false"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>
      </b-tab>
    </b-tabs>

    <ErrorMsgDialog
      v-model="msgERR.show"
      :modal="msgERR.modal"
      :title="msgERR.title"
      :content="msgERR.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      :isTypeError="true"
      proceedButton="OK"
    ></ErrorMsgDialog>

    <InfoMsgDialog
      v-model="msgINFO.show"
      :modal="msgINFO.modal"
      :title="msgINFO.title"
      :content="msgINFO.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      proceedButton="OK"
      @onProceed="infoProceedOK()"
    ></InfoMsgDialog>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  errorMessages,
  // extractPagingInfoOnly,
} from "@/core/services/helper.service";

import {
  GET_APPS_ROLE_SALES_ASSIST,
  GET_APPS_ROLE_SALES_PROCESS,
  GET_APPS_ROLE_SALES_REMARK,
  GET_APPS_ROLE_SALES_APPRVD,
} from "@/core/services/store/application.module";

import DataList from "@/view/pages/widgets/DataList.vue";
import StatusSummary from "@/view/pages/widgets/StatusSummary.vue";

export default {
  name: "SalesOnlineDataList",

  components: {
    DataList,
    StatusSummary,
  },

  props: {
    // forms: {},
    // needHelpApps: {},
    // receivedApps: {},
    // completeApps: {},
    // returnedApps: {},
    // approvedApps: {},
  },

  data() {
    return {
      overlay: true,

      msgERR: {
        show: false,
        modal: false,
        title: "Ralat",
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        show: false,
        modal: false,
        title: "Info",
        message: "",
        nric: "",
        run: 0,
      },

      statuses: [
        {
          id: 1,
          title: "Permohonan",
          value: "20",
          class: "blue",
        },
        {
          id: 2,
          title: "Lulus",
          value: 2,
          class: "green",
        }
      ],

      headers: [
        {
          text: "No. Rujukan",
          value: "ref_no",
          type: String,
        },
        {
          text: "Tarikh Mohon",
          value: "submitted_date",
          type: Date,
          filter: "formatDateTime",
        },
        {
          text: "Status",
          value: "status.nameMS",
          color: "status.color_class",
          type: String,
          filter: "upperCase",
          class_template: {
            "h-25": true,
            "label": true,
            "label-md": true,
            "label-inline": true,
            "font-weight-bold": true,
          },
        },
        {
          text: "Tarikh Kelulusan",
          value: "approval_date",
          type: Date,
          filter: "formatDate",
        },
        {
          text: "Tarikh Pembayaran",
          value: "disb_date",
          type: Date,
          filter: "formatDate",
        },
        {
          text: "Jumlah Pembiayaan Diluluskan",
          value: "approved_amount",
          type: Number,
          filter: "fAmount",
          style: "white-space: nowrap;",
          class_template: {
            "text-primary": true,
          },
        },
        {
          text: "Surat Tawaran",
          value: "media_name",
          type: String,
        },
        {
          text: "Nota",
          value: "remark",
          type: String,
          tooltip: {
            style: "white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 270px;",
            class: "text-dark-75",
          }
        },
      ],

    };
  },

  computed: {
    ...mapGetters([
      "pagedAppsRoleSalesAssistable",
      "pagedAppsRoleSalesRemarked",
      "pagedAppsRoleSalesProcess",
      "pagedAppsRoleSalesApproved",
      // "pagedAppsRoleMember",
      // "pagedAppsRoleSalesAssistable",
      // "paginateApplications",
      // "completeApplications",
      // "approvedApplications",
      // // "notifications",
      // "paginateRemarkApplications",
      // "paginateUnassignedApplications",
      // "pendingApplicationBatches",
      // "unassignedApplications",
      // "logs",
    ]),

  },

  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },

    setCheck(check) {
      if (check) {
        this.checked = check;
      } else {
        this.checked = false;
      }
    },

    // paginate(url) {
    //   this.$emit("paginate", url);
    // },

    goToEditPage(data) {
      this.$router.push({
        name: "EditForm",
        params: { id: data.id },
      });
    },

    goToDeleteApp(data) {
    },

    // deleteApplication(form) {
    //   this.$emit("showAlertDeleteApplicationDialog", form);
    // },

  },

  mounted() {
    //nfh-console.log(' IN:MOUNTED');
    this.$store.dispatch(GET_APPS_ROLE_SALES_ASSIST);
    this.$store.dispatch(GET_APPS_ROLE_SALES_PROCESS);
    this.$store.dispatch(GET_APPS_ROLE_SALES_REMARK);
    this.$store.dispatch(GET_APPS_ROLE_SALES_APPRVD);
    //nfh-console.log('OUT:MOUNTED');
  },

};
</script>
