<template>
  <v-row justify="center">
    <v-dialog v-model="displayAssignApplication" max-width="400">
      <v-card class="pa-6">
        <v-row>
          <v-col cols="12">
            <v-card-title class="px-0 py-0">
              <span class="font-weight-bold font-size-sm">
                Tugaskan kepada Processor berikut:
              </span>
            </v-card-title>
            <v-card-text class="px-0 py-0">
              <div>
                <ValidationObserver ref="assignApplication">
                  <v-form>
                    <v-container fluid>
                      <v-row>
                        <v-col class="py-0 px-0" cols="12" md="12">
                          <ValidationProvider v-slot="{ errors }" name="Tugasan" rules="required">
                            <v-select v-model="salesExecId" :items="userByBranch" item-text="full_name" item-value="id"
                              :error-messages="errors" outlined clearable dense required></v-select>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <div align="right">
              <v-btn text @click="closeAssignApplicationDialog()"><span class="font-weight-bold">Kembali</span></v-btn>
              <v-btn class="ml-4" color="primary" :loading="isLoading" @click="assignApplication()">
                <span class="font-weight-bold">Teruskan</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import { required } from "vee-validate/dist/rules";
import {
  GET_USER,
  GET_USER_BY_BRANCH,
  SET_USER_BY_BRANCH,
} from "@/core/services/store/user.module";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    application: {},
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: false,
      salesExecId: null,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserRoles", "userByBranch"]),
    displayAssignApplication: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    isInArray: function () {
      return this.currentUserRoles.includes(value);
    },
  },
  async mounted() {
    await this.$store.dispatch(GET_USER);
    // xxconsole.log(this.currentUserRoles.includes("Branch Manager"));
    if (this.currentUserRoles.includes(4) == true) {
      // xxconsole.log("Call GET_USER_BY_BRANCH");
      await this.$store.dispatch(GET_USER_BY_BRANCH).then((resp) => {
        // xxconsole.log(resp.data.data);
        this.$store.commit(SET_USER_BY_BRANCH, resp.data.data);
      });
    }
  },
  methods: {
    assignApplication() {
      this.$refs.assignApplication.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          setTimeout(() => {
            this.$emit("onAssignApplication", this.salesExecId);
            // xxconsole.log(this.salesExecId);
            this.isLoading = false;
          }, 2000);
        }
      });
    },
    closeAssignApplicationDialog() {
      this.displayAssignApplication = false;
    }
  },
};
</script>
<style>
.v-text-field--outlined {
  font-size: 12px;
}

.v-text-field--outlined label {
  font-size: 12px;
}
</style>
